import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import MdxCard from 'src/components/MdxCard';
import SEO from 'src/components/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Grid syatem" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "grid-system"
      }}>{`Grid System`}</h2>
      <p>{`Pal.js UI Grid like bootstrap grid the different in use here we use javaScript functions and React Component`}</p>
      <p>{`Grid system use these variables.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// this default grid system`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` gridSize `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`12`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// grid column number`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` gridGutter `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`24`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//padding 24 (12px on each side of a column)`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// default breakpoints`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` breakpoints `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  xs`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  is`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`400`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  sm`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`576`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  md`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`768`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  lg`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`992`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  xl`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1200`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  xxl`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1400`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  xxxl`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1600`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` maxContainer `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  is`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`380`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  sm`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`540`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  md`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`720`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  lg`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`960`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  xl`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1140`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  xxl`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1320`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  xxxl`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1500`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h3 {...{
        "id": "customize"
      }}>{`Customize`}</h3>
      <p>{`To customize this variables you need to send your values with theme settings
sorry for now you can't customize breakpoints but will work on it`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// here you can pass variables`}</span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`theme`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`themes`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`theme`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` gridSize`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token number"
                }}>{`10`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` gridGutter`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token number"
                }}>{`30`}</span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
  `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Layout`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`dir`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`dir`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`
`}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`ThemeProvider`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "breakpoint-functions"
      }}>{`Breakpoint functions`}</h2>
      <p>{`Pal.js UI themes have 4 breakpoints functions to use simply in styled components`}</p>
      <h4 {...{
        "id": "breakpointup"
      }}>{`breakpointUp`}</h4>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` css `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` breakpointUp `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui/breakpoints'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Example: Hide starting at \`min-width: 0\`, and then show at the \`md\` breakpoint`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Component `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`div`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  display: none;
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`breakpointUp`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token string"
                }}>{`'md'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    display: block;
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "breakpointdown"
      }}>{`breakpointDown`}</h4>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` css `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` breakpointDown `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui/breakpoints'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Example: Hide from \`xxxl\`, and then show at the \`md\` breakpoint to \`min-width: 0;\``}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Component `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`div`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  display: none;
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`breakpointDown`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token string"
                }}>{`'md'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    display: block;
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "breakpointbetween"
      }}>{`breakpointBetween`}</h4>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` css `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` breakpointBetween `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui/breakpoints'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Example: show in width between 768 and 1200 this take lower breakpoint, upper breakpoint`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Component `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`div`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  display: none;
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`breakpointBetween`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token string"
                }}>{`'md'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="span" {...{
                  "className": "token string"
                }}>{`'xl'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    display: block;
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "breakpointonly"
      }}>{`breakpointOnly`}</h4>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` css `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` breakpointOnly `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui/breakpoints'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Example: show in width between 768 and 991 from md to under lg`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Component `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`div`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  display: none;
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`breakpointOnly`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token string"
                }}>{`'md'`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    display: block;
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "related-articles"
      }}>{`Related Articles`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/ui/components/grid"
          }}>{`Grid Components`}</a>{`.`}</li>
      </ul>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      